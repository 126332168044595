@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: default; /* Define o cursor padrão para todo o corpo */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Garante o cursor correto para elementos interativos */
button, a {
  cursor: pointer; /* Indica que esses elementos são clicáveis */
}

/* Corrige possíveis estilos globais indesejados */
* {
  cursor: default; /* Previne o cursor de texto ou outros padrões errados */
}

/* Exemplo para inputs de texto: mantém o cursor de digitação */
input, textarea {
  cursor: text;
}

/* Exemplo para áreas de carregamento */
.loading {
  cursor: wait; /* Mostra o cursor de carregamento */
}

/* Adicione estilos para melhorar a acessibilidade visual */
button, a {
  text-decoration: none;
  border: none;
  outline: none;
  background-color: transparent; /* Garante que botões sem estilo tenham fundo transparente */
}

